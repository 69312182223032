<template>
  <div>
  <base-page
    :title="$t('serverManage.title')"
    :formData="formData"
    :config="config"
    @functionMethod="functionMethod"
    @search="search"
    @openDialog="openDialog"
  >
    <table-list
      ref="formList"
      :url="'/' + $util.getLocalStorage('apiprefix') + $api.findCompanyProductServiceList"
      :columns="columns"
      :defaultParams="defaultParams"
      v-model="tableRow"
      :rowKey="rowKey"
    >
     <!-- 开通时间 -->
      <template v-slot:grantTime="{ row }">
        {{ $util.format(row.grantTime, 'yyyy-MM-dd') }}
      </template>
    <!-- 操作 -->
      <template v-slot:option="{ row }">
        <el-button v-if="$page.showBtn('PRODUCT_SERVICE_MANAGE_UPDATE')" type="text" @click="editServer(row)">
          {{ $t('functionEnum.PRODUCT_SERVICE_EDIT') }}
        </el-button>
      </template>
    </table-list>
    <detail v-if="showDetail"
    :editRow="editRow"
    :editTrue="editTrue"
    @closePage="closePage"/>
    <dialog-table-list
      width="1200px"
      v-if="showDialogTableList"
      ref="tableList"
      :title="dialogTitle"
      :url="dialogUrl"
      :columns="dialogColumns"
      :formData="dialogFormData"
      :config="dialogConfig"
      :defaultParams="dialogDefaultParams"
      v-model="dialogTableRow"
      @cancel="dialogCancel"
      @confirm="dialogConfirm"
      @search="dialogSearch"
    >
      <!-- 创建时间 -->
      <template v-slot:createTime="{ row }">
        {{ $util.format(row.createTime, 'yyyy-MM-dd') }}
      </template>
    </dialog-table-list>
  </base-page>

  </div>
</template>

<script>
import Detail from './detail'

export default {
  components: {
    Detail,
  },
  data() {
    const nowTime = new Date().getTime()
    const prevTime = nowTime - 365 * 24 * 60 * 60 * 1000
    const startCreateTime = this.$util.format(prevTime, 'yyyy-MM-dd')
    const endCreateTime = this.$util.format(nowTime, 'yyyy-MM-dd')
    const _that = this
    return {
      defaultParams: {
        startCreateTime: new Date(startCreateTime + ' 00:00:00').getTime(),
        endCreateTime: new Date(endCreateTime + ' 23:59:59').getTime(),
      },
      // 搜索
      formData: {
        createTime: [startCreateTime, endCreateTime],
      },
      config: [
        {
          tag: 'dialog-define',
          label: this.$t('serverManage.empowerCpmpany'),
          prop: 'companyId',
          span: 8,
          hidden: false,
          rules: {
            noNull: true,
          },
          tagEvents: {
            // 显示名称
            showName(data) {
              return data && data.name
            },
          },
        },
        // lang:开始时间和结束时间
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 8,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ],
      // 表格页
      tableRow: null,
      editRow: null,
      editTrue: false,
      contextFromObj: this.$util.listToObj(this.$dict.contextFrom()),
      yesOrNoObj: this.$util.listToObj(this.$dict.yesOrNo()),
      columns: [
        // lang:开通时间
        {
          label: this.$t('serverManage.openTime'),
          prop: 'grantTime',
        },
        // lang:开通企业
        {
          label: this.$t('serverManage.openCompany'),
          prop: 'companyName',
        },
        // lang:开通详情
        {
          label: this.$t('serverManage.openDetails'),
          prop: 'productConfigs',
          callback: (row) => {
            const obj = []
            row.productConfigs.forEach(item => {
              if(item.isGrant === 'YES') {
                if(item.type === 'TMVA') return obj.push(_that.$t('businessEnum.TMVA'))
                if(item.type === 'XR_WORK')  return  obj.push(_that.$t('businessEnum.XR_WORK'))
                if(item.type === 'TMVA_IDC')  return  obj.push(_that.$t('businessEnum.TMVA_IDC'))
              }
            })
            var str = ""
            for (var i = 0; i < obj.length; i++) {
                str += obj[i] + "，"
            }
            if (str.length > 0) {
              str = str.substr(0, str.length - 1)
            }
            return str
          },
        },

        // lang:操作
        {
          label: this.$t('base.option'),
          prop: 'option',
        },
      ],
      // 详情页
      showDetail: false,
      // 弹出表格
      showDialogTableList: false,
      dialogTitle: '',
      dialogUrl: '',
      dialogDefaultParams: {},
      dialogColumns: [],
      dialogFormData: {},
      dialogConfig: {},
      dialogTableRow: null,
      searchCompanyID:'',
      //点击已选这项
      rowKey:'companyName'
    }
  },
  mounted() {
    // this.productServiceUpdate()
    // this.productTypeFind()
  },
  methods: {
// 确认表格弹框
    dialogConfirm(row) {
      this.formData[this.targetProp] = row
      this.showDialogTableList = false
    },
    // 打开表格弹框
    openDialog(item) {
      console.log(item)
      this.targetProp = item.prop
      this.dialogTableRow = this.formData[item.prop] || null
      // 选择设备
      this.dialogTitle = this.$t('adminAccount.companyInfo')
      this.dialogUrl = this.$api.companyFind
      this.dialogDefaultParams = {
        productType : this.$util.getLocalStorage('productType')
      }
      this.dialogColumns = [
        // lang:企业名称
        {
          label: this.$t('companyManage.name'),
          prop: 'name',
        },
        // lang:行业
        {
          label: this.$t('companyManage.industry'),
          prop: 'industry',
          callback(row) {
            return row && row.industry && row.industry.value
          },
        },
        // lang:地址
        {
          label: this.$t('companyManage.address'),
          prop: 'address',
          callback(row) {
            return row && row.address && row.address.value
          },
        },
        // lang:联系人
        {
          label: this.$t('companyManage.contacts'),
          prop: 'contacts',
        },
        // lang:联系方式
        {
          label: this.$t('companyManage.contactsInformation'),
          prop: 'contactsInformation',
        },
        // lang:邮箱
        {
          label: this.$t('companyManage.email'),
          prop: 'email',
        },
        // lang:业务描述
        {
          label: this.$t('companyManage.businessScope'),
          prop: 'businessScope',
        },
      ]
      this.dialogConfig = [
        {
          label: this.$t('base.searchStr'),
          prop: 'searchStr',
          span: 9,
          tagProps: {
            placeholder: this.$t('companyManage.nameOrNotes'),
          },
        },
        {
          tag: 'el-date-picker',
          label: this.$t('base.createTime'),
          prop: 'createTime',
          span: 11,
          className: 'daterangeDate',
          tagProps: {
            type: 'daterange',
            startPlaceholder: this.$t('base.startDate'),
            endPlaceholder: this.$t('base.endDate'),
          },
        },
      ]
      this.showDialogTableList = true
    },
    // 关闭表格弹框
    dialogCancel() {
      this.showDialogTableList = false
    },
    // 表格弹框搜索
    dialogSearch() {
      const dialogFormData = this.$util.copyData(this.dialogFormData)
      this.$refs.tableList.searchList(dialogFormData)
    },

    // 查询服务所有类型
    productTypeFind() {
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findProductTypeList,
      }).then((data) => {
        console.log(data)
       // this.config[1].tagProps.options = data
      })
    },
    // 关闭详情页回调
    closePage() {
      this.showDetail = false
      this.search()
    },
    // 查询按钮点击回调
    search(isActiveQuery) {
      const formData = this.$util.copyData(this.formData)
      if(formData.companyId) formData.companyId = formData.companyId.id
      this.defaultParams = {}
      this.$refs.formList.searchList(formData, isActiveQuery)
    },
    // 权限按钮点击回掉
    functionMethod(item) {
      console.log(item)
      const { method } = item
      if (method !== 'add' && !this.tableRow) {
        this.$element.showMsg(this.$t('base.selectTableRow'), 'warning')
        return
      }
      switch (method) {
        case 'add':
          this.showDetail = true
          this.editTrue = false
          break
        default: // do something
      }
    },
    editServer(row) {
      this.editRow = row
      this.showDetail = true
      this.editTrue = true
      // this.editTitle = this.$t('serverManage.modifyProduct'),
      console.log(row)
    },
  },
}
</script>
